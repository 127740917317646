@mixin get-rules-range($selector, $prop, $factors) {
  @each $factor in $factors {
    #{$selector}-#{$factor} {
      #{$prop}: #{$factor * 4}px !important;
    }
  }
}

@include get-rules-range('.mt', 'margin-top', 2 4 6);

@include get-rules-range('.mb', 'margin-bottom', 1 2 3 4 6 8 10 12);

@include get-rules-range('.mr', 'margin-right', 3 4);

.mark {
  color: var(--brand-color);
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hidden-strong {
  display: none;
}