.FileUpload {
  &__label {
    padding: 15px 16px;
    border: 1px solid var(--gray-2-color);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    transition: border-color 350ms;
    line-height: 1;
    cursor: pointer;
  }

  input:disabled + &__label {
    cursor: default;
  }

  &__label:hover,
  input:focus + &__label {
    transition-duration: 175ms;
    border-color: var(--gray-3-color);
  }

  input:focus + &__label {
    cursor: default;
  }

  &__label-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
  }

  &__clip {
    width: 9px;
    height: 20px;
  }
}