.EventHeadline {
  display: flex;

  &__item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: var(--nxzuC);
    margin-right: 4px;
    transform: translateY(2px);
  }
}