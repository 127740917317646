.Attachment {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--tfvyC);
  padding-bottom: 16px;

  &__name,
  &__delete-btn {
    background: none;
    outline: none;
    padding: 0;
    border: none;
    font-family: inherit;
    color: inherit;
    transition: color 350ms;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--brand-light-color);
      transition-duration: 175ms;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    max-width: 90%;
    text-align: left;
    line-height: 1.2;
  }

  &__delete-btn {
    display: flex;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}