@import '../../../styles/breakpoints.scss';

.ProjectBlock {
  text-decoration: none;
  display: block;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  color: inherit;

  &__contract-name {
    display: block;
  }

  &__main-info {
    margin-bottom: -15px;
    position: relative;
    z-index: 2;
  }

  &__card {
    position: relative;
    display: block;
    height: 366px;
    text-decoration: none;
  }

  &__map {
    width: 100%;
    height: 260px;
    position: relative;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);

    @media #{$sm} {
      height: 230px;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url(../../../static/images/project-map-overlay.svg) no-repeat bottom center;
      background-size: 101% 150px;
      position: absolute;
      z-index: 400;
    }
  }
  
  &__info {
    background: var(--kfgjC);
    padding: 28px 32px 32px 32px;
  }

  &__card-content {
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__progress {
    position: absolute;
    top: 19%;
    right: 28px;
    z-index: 400;
  }
}
