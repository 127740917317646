.SelectableItem {
  $root: &;
  border: 1px solid var(--gray-2-color);
  display: flex;
  align-items: center;
  padding: 18px 16px;
  transition: border-color 350ms;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;

  &__label {
    &_middle-content {
      align-items: center;
    }

    &:hover,
    #{$root}__field:focus + & {
      border-color: var(--gray-3-color);
    }

    #{$root}__field:checked + & {
      transition-duration: 175ms;
      border-color: var(--brand-dark-color);
    }
  }

  &__title {
    color: var(--white-color);
    font-size: 20px;
    display: block;
  }
}