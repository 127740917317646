.MenuContent {
  $root: &;

  width: 100%;
  height: 100vh;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-top: 80px;
  background: var(--background-color);
  opacity: 0;
  visibility: hidden;
  transition: opacity 175ms, visibility 175ms;
  transition-delay: 300ms;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;

  &_open {
    opacity: 1;
    visibility: visible;
    transform: none;
    transition-delay: 0ms;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 450px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: auto 0;
  }

  &__item {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 175ms, opacity 175ms;

    @for $i from 0 through 2 {
      &:nth-child(#{$i + 1}) {
        transition-delay: (2 - $i) * 100ms;
      }
    }

    #{$root}_open & {
      opacity: 1;
      transform: none;

      @for $i from 0 through 2 {
        &:nth-child(#{$i + 1}) {
          transition-delay: $i * 100ms;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 56px;
    }
  }

  &__link {
    color: var(--white-color);
    text-decoration: none;
    transition: color 350ms;
    outline: none;
    user-select: none;
    position: relative;
    display: inline-block !important;
    padding-right: 24px;
    line-height: 1;

    &:hover,
    &:focus {
      transition-duration: 175ms;
      color: var(--white-dark-color);
    }

    &::after {
      content: '';
      display: block;
      width: 100vw;
      height: 1px;
      background: var(--brand-color);
      position: absolute;
      top: 18px;
      right: 0;
      transform: translateX(100%) scaleX(0);
      transform-origin: 100%;
      transition: transform 350ms;
    }

    #{$root}_open &_active::after {
      transform: translateX(100%);
    }
  }

  &__footer {
    border-top: 1px solid var(--gray-2-color);
    padding: 24px 0;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 175ms;

    #{$root}_open & {
      opacity: 1;
      transition-delay: 300ms;
    }
  }
}