.ProjectHeadline {
  background: var(--black-2-light-color);
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--headerPageHeight, 70px);
  display: flex;
  align-items: center;
  z-index: 1;
  border-bottom: 1px solid var(--tfvyC);

  &__content {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-family: Prata;
  }

  &__btn-back {
    margin-right: 24px;
    color: var(--nxzuC);
    transition: color 350ms;

    &:hover,
    &:focus {
      transition-duration: 175ms;
      color: var(--rxuyC);
    }

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(-180deg);
    }
  }

}