.Heading {
  color: var(--white-color);
  margin-top: 0;
  margin-bottom: 0;
  display: block;
  text-decoration: none;
  font-family: Prata;
  font-weight: 400;

  &_level {
    &_1 {
      font-size: 36px;
    }

    &_2 {
      font-size: 28px;
    }

    &_3 {
      font-size: 24px;
      line-height: 130%;
    }

    &_4 {
      font-size: 14px;
      text-transform: uppercase;
      color: var(--brand-color);
      font-family: Montserrat;
    }
  }

  &_primary {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: var(--brand-color);
  }
}