@import '../../../../styles/breakpoints.scss';

.ProjectProgress {
  height: 154px;
  width: 154px;
  position: relative;

  @media #{$sm} {
    width: 120px;
    height: 120px;
  }

  &__circles {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);

    circle {
      stroke: var(--dniyC);
      stroke-width: 3px;
    }

    circle:last-child {
      stroke: var(--nxzuC);
    }
  }

  &__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #000;
    font-size: 32px;
    color: var(--nxzuC);

    @media #{$sm} {
      font-size: 28px;
    }
  }

  // background: var(--gray-color);
  // height: 1px;

  // &__indicator {
  //   height: 100%;
  //   background: var(--brand-color);
  // }
}