.Hamburger {
  $root: &;

  background: none;
  outline: none;
  border: none;
  padding: 0;
  width: 39px;
  height: 17px;
  position: relative;
  cursor: pointer;

  $edgeLineTransitionForward: background 350ms, top 175ms 120ms, bottom 175ms 120ms, transform 175ms;
  $edgeLineTransitionBackward: background 350ms, top 175ms 120ms, bottom 175ms 120ms, transform 175ms 200ms;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 39px;
    height: 1px;
    background: var(--white-color);
    position: absolute;
    transition: $edgeLineTransitionForward;
  }

  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    background: var(--white-dark-color);
  }

  &::before {
    top: 0;
  }

  &_active::before {
    top: 8px;
    transform: rotate(45deg);
    transition: $edgeLineTransitionBackward;
  }
  
  &::after {
    bottom: 0;
  }

  &_active::after {
    bottom: 8px;
    transform: rotate(-45deg);
    transition: $edgeLineTransitionBackward;
  }

  &__middle-line {
    width: 30px;
    height: 1px;
    background: var(--white-color);
    display: block;
    position: absolute;
    right: 0;
    top: 8px;
    transition: background 350ms, transform 175ms 120ms, opacity 175ms 120ms;

    #{$root}_active & {
      transform: translateX(10px);
      opacity: 0;
      transition: transform 175ms, opacity 175ms 80ms;
    }

    #{$root}:hover &,
    #{$root}:focus & {
      background: var(--white-dark-color);
    }
  }

  &__click-area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
  }
}