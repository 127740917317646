.Paragraph {
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.01em;
  line-height: 140%;
  color: var(--font-color);
  font-size: 16px;

  &_small {
    font-size: 14px;
    line-height: 120%;
  }

  &_large {
    font-size: 18px;
  }

  p {
    margin: 0;
  }

  br {
    
  }
}