.Events {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 180px;
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    height: 32px;
    flex-shrink: 0;
  }

  &__wrapper {
    flex-grow: 1;
    position: relative;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__placeholder {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    opacity: .6;
  }
}