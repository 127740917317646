:root {
  --danger-color: hsl(354, 70%, 54%);

  --background-color: hsl(0, 0%, 9%);
  --color-1: hsl(0, 0%, 9%, 30%);

  --font-light-color: hsl(0, 0%, 100%);

  --brand-color: hsl(35, 54%, 62%);
  --brand-dark-color: hsl(34, 30%, 41%);
  --brand-light-color: hsl(35, 54%, 73%);

  --white-color: hsl(0, 0%, 100%);
  --white-dark-color: hsl(0, 0%, 80%);

  --black-color: hsl(0, 0%, 0%);
  --black-2-color: hsl(0, 0%, 8%);
  --black-2-light-color: hsl(0, 0%, 9%);
  --black-3-color: hsl(0, 0%, 13%);

  --gray-color: hsl(0, 0%, 27%);
  --gray-2-color: hsl(0, 0%, 22%);
  --gray-3-color: hsl(0, 1%, 35%);

  --danger-color: hsl(354, 70%, 54%);

  --background-color: hsl(0, 0%, 9%);

  --font-light-color: hsl(0, 0%, 100%);

  --brand-color: hsl(35, 54%, 62%);
  --brand-dark-color: hsl(34, 30%, 41%);
  --brand-light-color: hsl(35, 54%, 73%);

  --white-color: hsl(0, 0%, 100%);
  --white-dark-color: hsl(0, 0%, 80%);

  --black-color: hsl(0, 0%, 0%);
  --black-2-color: hsl(0, 0%, 8%);
  --black-2-light-color: hsl(0, 0%, 9%);
  --black-3-color: hsl(0, 0%, 13%);

  --gray-color: hsl(0, 0%, 27%);
  --gray-2-color: hsl(0, 0%, 22%);
  --gray-3-color: hsl(0, 1%, 35%);
}
