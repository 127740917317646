.ArrowLink {
  $root: &;

  color: var(--bkcuC);
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    color: var(--nxzuC);
    margin-left: 2px;
    transition: transform 350ms;
    will-change: transform;
  }

  &:hover svg {
    transform: translateX(5px);
    transition-duration: 175ms;
  }
}