.MessageType {
  &__attachment-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}