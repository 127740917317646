.Preloader {
  $root: &;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: preloader 1s linear infinite;
  }

  &_size {
    &_1 svg {
      width: 20px;
      height: 20px;
    }

    &_2 svg {
      width: 30px;
      height: 30px;
    }
  }

  &_primary {
    color: var(--brand-color);
  }

  &_centering {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
  }

  &_inline {
    display: inline-block;
    vertical-align: middle;
  }

  @keyframes preloader {
    to {
      transform: rotate(360deg);
    }
  }
}

