.LoginPage {
  padding-top: 10%;

  &__form {
    max-width: 480px;
  }

  &__forgot-password {
    font-size: 14px;
    color: var(--font-color);
    text-decoration: none;
    outline: none;
    transition: color 350ms;
    display: inline-block;

    &:hover,
    &:focus {
      transition-duration: 175ms;
      color: var(--font-light-color);
    }
  }
}