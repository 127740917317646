.Preloader {
  &__circle {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: var(--black-3-color);
  }

  &__line {
    background: var(--black-3-color);

    &_size {
      &_1 {
        height: 19px;
      }
      &_2 {
        height: 25px;
      }
      &_3 {
        height: 49px;
      }
      &_4 {
        height: 56px;
      }
    }
  }
}