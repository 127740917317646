.EventInfo {
  line-height: 1;

  &__separator {
    display: inline-block;
    width: 3px;
    height: 3px;
    background: currentColor;
    border-radius: 50%;
    vertical-align: middle;
  }
}