.SendButton {
  width: 48px;
  height: 48px;
  background: var(--nxzuC);
  color: var(--dniyC);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: none;
  appearance: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: opacity 175ms;

  &:disabled {
    opacity: .85;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}