.Event {
  $root: &;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 24px;
  }

  &_client {
    margin-left: 20%;

    &::before {
      right: 0;
      transform: rotate(-45deg);
      background: var(--tfvyC);
    }
  }

  &_user {
    margin-right: 5%;

    &::before {
      border: 1px solid var(--tfvyC);
      border-width: 1px 0 0 1px;
      left: 0;
      transform: rotate(-45deg);
      background: var(--dniyC);
    }
  }

  &__content {
    padding: 16px 12px 24px;
    border-radius: 16px;

    #{$root}_client & {
      margin-right: 6px;
      background: var(--tfvyC);
    }

    #{$root}_user & {
      border: 1px solid var(--tfvyC);
      margin-left: 6px;
    }
  }

  &__body {
    background: var(--dniyC);
    border-radius: 24px;
    padding: 20px;
  }

  &__title {
    font-size: 18px;
    line-height: 140%;
    font-weight: 500;
    display: block;
  }

  &__separator {
    width: 48px;
    border-bottom: 1px solid var(--nxzuC);
    margin: 16px 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}