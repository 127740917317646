.FeedCompose {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--jpssC);
  padding: 16px 0;

  &__field {
    margin-right: 8px;
    flex-grow: 1;
    width: 0;
  }

  &__content {
    display: flex;
  }

  &__attachment {
    border-radius: 16px;
    font-size: 16px;
    padding: 13px 16px;
    border-color: var(--bkcuC);
  }

  &__list {
    max-height: 250px;
    overflow-y: auto;
  }
}