.Input {
  border: 1px solid var(--gray-2-color);
  border-radius: 12px;
  background: none;
  padding: 15px 16px;
  font-size: 19px;
  font-family: inherit;
  color: var(--white-color);
  outline: none;
  width: 100%;
  transition: border-color 350ms;
  appearance: none;

  &:hover,
  &:focus {
    transition-duration: 175ms;
    border-color: var(--gray-3-color);
  }

}