.Button {
  font-family: inherit;
  font-size: 16px;
  outline: none;
  user-select: none;
  text-decoration: none;
  will-change: transform;
  color: unset;
  background: none;
  border-radius: 10px;
  line-height: 1;
  padding: 19px 24px;
  border: 1px solid transparent;
  transition: transform 175ms;
  display: inline-block;
  cursor: pointer;

  &:not(:disabled):active {
    transform: translateY(2px);
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &_filled {
    border: none;
    letter-spacing: 0.03em;
    color: var(--dniyC);
    background-color: var(--nxzuC);

    transition: background-color 350ms, transform 175ms;
    font-weight: 500;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      transition-duration: 175ms;
      background-color: var(--gkecC);
    }
  }

  &_border {
    color: var(--nxzuC);
    border-color: currentColor;
    transition: color 350ms, transform 175ms;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: var(--brand-light-color);
      transition-duration: 175ms;
    }
  }

  &_secondary {
    background: var(--tfvyC);
  }

  &_upper {
    text-transform: uppercase;
    letter-spacing: 0.08em;
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 12px;
    transform: scale(1.6) translateY(1px);
  }
}