.Modal {
  &__overlay {
    position: fixed;
    z-index: 10001;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--jfkeC);
    will-change: transform;
    overflow: auto;
    text-align: center;

    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  &__container {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    background-color: var(--dniyC);
    max-width: 460px;
    width: 95%;
    box-sizing: border-box;
    position: relative;
    will-change: transform;
    margin: 40px 0;
    border-radius: 16px;
    border: 1px solid var(--tfvyC);
  }

  &__header {
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid var(--tfvyC);
  }

  &__close {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    display: flex;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    padding: 24px;
    font-size: 18px;
  }
}