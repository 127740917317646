.Textarea {
  border: 1px solid var(--gray-2-color);
  background: none;
  padding: 15px 16px;
  font-size: 19px;
  font-family: inherit;
  color: var(--white-color);
  outline: none;
  width: 100%;
  resize: vertical;
  max-height: 300px;
  min-height: 100px;
  transition: border-color 350ms;
  border-radius: 12px;

  &:hover,
  &:focus {
    transition-duration: 175ms;
    border-color: var(--gray-3-color);
  }
}