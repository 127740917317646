.Menu {
  width: 100%;
  height: 80px;
  background: var(--background-color);
  position: fixed;
  top: 0;
  z-index: 1001;
  border-bottom: 1px solid var(--gray-2-color);

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    display: block;
  }

  &__button {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    flex-shrink: 0;
    outline: none;
    cursor: pointer;
    color: var(--white-color);
    transition: color 350ms;

    &:hover,
    &:focus {
      transition-duration: 175ms;
      color: var(--brand-color);
    }

    &_first {
      border-right: 1px solid var(--gray-2-color);
    }

    &_last {
      border-left: 1px solid var(--gray-2-color);
    }
  }

  &__button-icon {
    width: 30px;
    height: 30px;
    
  }

  &__submenu-wrap {
    position: relative;
  }

  &__submenu {
    position: absolute;
    right: 10px;
    transform: translateY(-10px);
  }
}