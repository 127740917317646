.Select {
  appearance: none;
  background: none;
  padding: 15px 54px 15px 16px;
  font-size: 19px;
  font-family: inherit;
  border: 1px solid var(--gray-2-color);
  border-radius: 12px;
  outline: none;
  width: 100%;
  transition: border-color 350ms;
  background: url(../../static/images/select-arrow.svg) no-repeat;
  background-size: 22px;
  background-position: right 16px center;
  color: inherit;
  cursor: pointer;

  &:hover,
  &:focus {
    transition-duration: 175ms;
    border-color: var(--gray-3-color);
  }

  option {
    color: initial;
  }
}