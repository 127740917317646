@import "../../../../../../../styles/breakpoints.scss";

.UserEventLayout {
  @media #{$esm-min} {
    display: flex;
  }

  &__aside {
    width: 54px;
    height: 54px;
    margin-right: 12px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--nxzuC);
    border-radius: 50%;
    color: var(--dniyC);

    @media #{$esm} {
      margin-bottom: 16px;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
}