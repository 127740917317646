@font-face {
  font-family: Montserrat;
  src: url('../static/fonts/Montserrat-Regular.woff2') format('woff2'),
       url('../static/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url('../static/fonts/Montserrat-Medium.woff2') format('woff2'),
       url('../static/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: Prata;
  src: url('../static/fonts/Prata-Regular.woff2') format('woff2'),
       url('../static/fonts/Prata-Regular.woff') format('woff');
  font-weight: 400;
}

:root {
  --danger-color: hsl(354, 70%, 54%);

  --background-color: hsl(0, 1%, 14%);

  --font-color: hsl(0, 0%, 87%);
  --font-light-color: hsl(0, 0%, 100%);

  --brand-color: hsl(35, 54%, 62%);
  --brand-light-color: hsl(35, 54%, 73%);

  --white-color: hsl(0, 0%, 100%);
  --white-dark-color: hsl(0, 0%, 80%);

  --black-color: hsl(0, 0%, 0%);
  --black-2-color: hsl(0, 0%, 8%);
  --black-2-light-color: hsl(0, 0%, 9%);

  --gray-color: hsl(0, 0%, 27%);
  --gray-2-color: hsl(0, 0%, 22%);
  --gray-3-color: hsl(0, 1%, 35%);

  --kfgjC: hsl(0, 0%, 4%);
  --lfjtC: hsl(0, 0%, 1%);
  --jpssC: hsl(0, 0%, 0%);
  --hgjuC: hsl(250, 43%, 10%);
  --gkecC: hsl(35, 54%, 56%);
  --nxzuC: hsl(35, 54%, 62%); // Brand
  --rxuyC: hsl(35, 54%, 73%);
  --dniyC: hsl(0, 0%, 9%);
  --jfkeC: hsla(0, 0%, 9%, 80%);
  --tfvyC: hsl(0, 0%, 22%);
  --bkcuC: hsl(0, 0%, 87%);
  --fkquC: hsl(0, 1%, 14%);
  --wdghC: hsl(0, 0%, 100%);

  --menuHeight: 80px;
  --pagePaddingTop: 48px;
}

html {
  height: 100%;
}

body {
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.01em;
  margin: 0;
  background: var(--background-color);
  color: var(--font-color);
  height: 100%;
  line-height: 140%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  background: none;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  border: none;
  color: inherit;
  outline: none;
  user-select: none;
  cursor: pointer;
}

// ::-webkit-scrollbar {
//   // background: red;
//   width: 8px;
// }

// ::-webkit-scrollbar-track {
//   background: #1d1d1d;
//   border-radius: 4px;
//   // background: red;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background: var(--tfvyC);
// }

@import "../../node_modules/swiper/swiper.scss";

@import "./variables.scss";

@import "./blocks/Wrap.scss";
@import "./blocks/Heading.scss";
@import "./blocks/Paragraph.scss";
@import "./blocks/Input.scss";
@import "./blocks/Select.scss";
@import "./blocks/Textarea.scss";
@import "./blocks/Label.scss";
@import "./blocks/ErrorMessage.scss";
@import "./blocks/SelectableItem.scss";
@import "./blocks/Preloader.scss";
@import "./blocks/Link.scss";

@import "./utils.scss";
