.LogoutButton {
  text-decoration: none;
  color: var(--font-color);
  font-size: 17px;
  display: flex;
  align-items: center;
  transition: color 350ms;
  outline: none;

  &:hover,
  &:focus {
    transition-duration: 175ms;
    color: var(--white-color);
  }

  svg {
    color: var(--brand-color);
    width: 16px;
    height: 16px;
    margin-left: 14px;
  }
}