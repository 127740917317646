.Link {
  color: var(--brand-color);
  background: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  display: inline-block;
  transition: color 350ms, opacity 350ms;
  cursor: pointer;
  text-align: left;
  text-decoration: none;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    transition-duration: 175ms;
    color: var(--brand-light-color);
  }

  &:disabled {
    opacity: .5;
    cursor: progress;
  }
}